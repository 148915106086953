import './App.css';

function App() {
  return (
    <div id="page-top">
      <div id="apDiv2">
        <a href="#myModal">
           
          </a>
      </div>

      <div id="apDiv4">
        <div className="apDiv4-call" data-toggle="modal" data-target="#myModals">
          <i className="fa fa-car faa-tada animated"></i>&ensp;&ensp;Site Visit
        </div>
      </div>
      <div id="apDiv5">
        <div className="apDiv5-call"><a href="tel:+919130568016">
          <i className="fa fa-phone faa-tada animated"></i>&ensp;+91 9130568016</a>
        </div>
      </div>


      <div id="myModal" className="modal fade" role="dialog">
         <div className="modal-dialog">
            <div className="modal-content">
               <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                  <h4 className="modal-title pop_align">Raunak Urban Centre Kalyan Brochure</h4>
               </div>
               <div className="modal-body">
                  <form action="maildownload.php" method="post">
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-user"></i></span>
                           <input className="form-control" name="fname" id="fnames" placeholder="Name" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                           <input type="email" className="form-control" name="email" id="emails" placeholder="Email" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                           <input type="text" className="form-control" name="mobile" id="mobiles" placeholder="Mobile" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-comment"></i></span>
                           <textarea className="form-control" name="mesg" id="mesgs" placeholder="Massage" rows="3"></textarea>
                        </div>
                     </div>
                     <div className="pop_align">
                        <div className="form-group">
                           <input type="submit" name="save" className="btn btn-sl-form"  value="Submit" />
                        </div>
                     </div>
                     <div className="success_message1 pop_align"></div>
                  </form>
               </div>
            </div>
         </div>
      </div>
	  
	  
	  <div id="ganesh" className="modal fade" role="dialog">
			<div className="modal-dialog modal-sm">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
						<h4 className="modal-title pop_align">Book Free Site Visit</h4>
					</div>
					<div className="modal-body">
					  
					<form  method="post" id="bookvisit">
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-user"></i></span>
								<input className="form-control" name="fname" id="fname1" placeholder="Name *" required />
							</div>
						</div>
						 <div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
										 <input type="text" className="form-control" name="mobile" id="mobile1" placeholder="Mobile *" required />
							</div>
						</div>
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-calendar "></i></span>
								<input type="date" className="form-control" name="date" id="date1" placeholder="Date *" required />
								 
				   
							</div>
						</div>
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-map-marker"></i></span>
								<textarea className="form-control" name="location" id="location2" placeholder="Location *" required></textarea>
							</div>
						</div>
					  <div className="pop_align">
					 <div className="form-group">
						<input type="submit" className="btn btn-sl-form" value="Submit"/>
					 </div>
					</div>
					<div className="success_offerspop pop_align"></div>
				</form>
					</div>
				</div>
			</div>
		</div>
		
		
		<div id="pricing" className="modal fade" role="dialog">
			<div className="modal-dialog modal-sm">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
						<h4 className="modal-title pop_align">Get Offer Price</h4>
					</div>
					<div className="modal-body">
					   
					<form method="post" id="pricing1">
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-user"></i></span>
								<input className="form-control" name="fname" id="fnameprice" placeholder="Name *" required />
							</div>
						</div>
						 <div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
								<input type="text" className="form-control" name="mobile" id="mobileprice" placeholder="Mobile *" required />
							</div>
						</div>
						
					  <div className="pop_align">
					 <div className="form-group">
						<input type="submit" className="btn btn-sl-form" value="Submit"/>
					 </div>
					</div>
					<div className="success_price pop_align"></div>
				</form>
					</div>
				</div>
			</div>
		</div>
		
		<div id="floorplan" className="modal fade" role="dialog">
			<div className="modal-dialog modal-sm">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
						<h4 className="modal-title pop_align">Show Unit Plans</h4>
					</div>
					<div className="modal-body">
					  
					<form  method="post" id="floorplan1">
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-user"></i></span>
								<input className="form-control" name="fname" id="fnamefloor" placeholder="Name *" required />
							</div>
						</div>
						 <div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
										 <input type="text" className="form-control" name="mobile" id="mobilefloor" placeholder="Mobile *" required />
							</div>
						</div>
						
					  <div className="pop_align">
					 <div className="form-group">
						<input type="submit" className="btn btn-sl-form" value="Submit"/>
					 </div>
					</div>
					<div className="success_floor pop_align"></div>
				</form>
					</div>
				</div>
			</div>
		</div>
		
		<div id="commonform" className="modal fade" role="dialog">
			<div className="modal-dialog modal-sm">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
						<h4 className="modal-title pop_align">Show Gallery</h4>
					</div>
					<div className="modal-body">
					  
					<form  method="post" id="floorplan1">
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-user"></i></span>
								<input className="form-control" name="fname" id="fnamecommon" placeholder="Name *" required />
							</div>
						</div>
						 <div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
										 <input type="text" className="form-control" name="mobile" id="mobilecommon" placeholder="Mobile *" required />
							</div>
						</div>
						
					  <div className="pop_align">
					 <div className="form-group">
						<input type="submit" className="btn btn-sl-form" value="Submit"/>
					 </div>
					</div>
					<div className="success_common pop_align"></div>
				</form>
					</div>
				</div>
			</div>
		</div>
		
		<div id="ganesh" className="modal fade" role="dialog">
			<div className="modal-dialog modal-sm">
				<div className="modal-content">
					<div className="modal-header">
						<button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
						<h4 className="modal-title pop_align">Site Visit Pickup & Drop</h4>
					</div>
					<div className="modal-body">
					  
					<form  method="post" id="pickdrop">
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-user"></i></span>
								<input className="form-control" name="fname" id="fname1" placeholder="Name *" required />
							</div>
						</div>
						 <div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
										 <input type="text" className="form-control" name="mobile" id="mobile1" placeholder="Mobile *" required />
							</div>
						</div>
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-calendar "></i></span>
								<input type="date" className="form-control" name="date" id="date1" placeholder="Date *" required />
				   
							</div>
						</div>
						<div className="form-group">
							<div className="input-group"> 
								<span className="input-group-addon"><i className="fa fa-map-marker"></i></span>
								<textarea className="form-control" name="location" id="location2" placeholder="Location *" required></textarea>
							</div>
						</div>
					  <div className="pop_align">
					 <div className="form-group">
						<input type="submit" className="btn btn-sl-form" value="Submit"/>
					 </div>
					</div>
					<div className="success_offerspop pop_align"></div>
				</form>
					</div>
				</div>
			</div>
		</div>


      <div id="myModals" className="modal fade">
         <div className="modal-dialog">
            <div className="modal-content">
               <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                  <h4 className="modal-title pop_align">Get in touch for Raunak Urban Centre Kalyan </h4>
               </div>
               <div className="modal-body">
                  <form  method="post" id="signupForm2">
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-user"></i></span>
                           <input className="form-control" name="fname" id="fnameb" placeholder="Name" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                           <input type="email" className="form-control" name="email" id="emailb" placeholder="Email" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group">
                           <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                           <div className="col-lg-3">
                              <div className="row">
                                 <select name="code" id="codes" className="form-control" >
                                    <option  value="">Select Country</option>
                                    <option value="+91">India</option>
                                    <option value="+971">United Arab Emirates</option>
                                    <option value="+1">USA</option>
                                    <option value="+1">Canada</option>
                                    <option value="+44">United Kingdom</option>
                                    <option value="+61">Australia</option>
                                    <option value="+65">Singapore</option>
                                    <option value="+974">Qatar</option>
                                    <option value="+968">Oman</option>
                                    <option value="+64">New Zealand</option>
                                    <option value="+60">Malaysia</option>
                                    <option value="+852">Hong Kong</option>
                                    <option value="+0">Others</option>
                                 </select>
                              </div>
                           </div>
                           <div className="col-lg-9">
                              <div className="row">
                                 <input type="text" className="form-control" name="mobile" id="mobileb" placeholder="Mobile" />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-comment"></i></span>
                           <textarea className="form-control" name="mesg" id="mesgb" placeholder="Massage" rows="3"></textarea>
                        </div>
                     </div>
                     <div className="pop_align">
                        <div className="form-group">
                           <input type="submit" className="btn btn-sl-form" value="Submit" />
                        </div>
                     </div>
                     <div className="success_message2 pop_align"></div>
                  </form>
               </div>
            </div>
         </div>
      </div>

      <div id="feedback">
         <div id="feedback-form" className="col-xs-4 col-md-4 panel panel-default displaynone">
            <h3>Instant Call Back</h3>
            <form  method="post" id="signupForm3">
               <div className="form-group">
                  <div className="input-group"> 
                     <span className="input-group-addon"><i className="fa fa-user"></i></span>
                     <input className="form-control" name="fname" id="fnamec" placeholder="Name" />
                  </div>
               </div>
               <div className="form-group">
                  <div className="input-group"> 
                     <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                     <input type="email" className="form-control" name="email" id="emailc" placeholder="Email" />
                  </div>
               </div>
               <div className="form-group">
                  <div className="input-group"> 
                     <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                     <input type="text" className="form-control" name="mobile" id="mobilec" placeholder="Mobile" />
                  </div>
               </div>
               <div className="pop_align">
                  <div className="form-grop">
                     <input type="submit" className="btn btn-sl-form" value="Submit"/>
                  </div>
               </div>
               <div className="success_message3 float_left"></div>
            </form>
         </div>
         <div id="feedback-tab">Instant Call Back</div>
      </div>
    
      <nav id="mainNav" className="navbar navbar-default navbar-fixed-top" >
         <div className="container">
            <div className="navbar-header">
	 
               <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
               <span className="sr-only">Toggle navigation</span> Menu <i className="fa fa-bars"></i>
               </button>
			    
               <a className="navbar-brand page-scroll" href="#page-top">
               <img src="img/urbanlogo.png" width="95" className="img-responsive " alt="Raunak Urban Centre" title="Raunak Urban Centre" />
               </a>
            </div>
            <div className="cell">
               <a href="tel:+919130568016">
                  <div className="col-md-2 cell_phone"><i className="fa fa-phone"></i>&ensp;+91 91305 68016</div>
               </a>
            </div>
            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
               <ul className="nav navbar-nav navbar-right">
                  <li><a className="page-scroll" href="#page-top">Home</a></li>
                  <li><a className="page-scroll" href="#about">About</a></li>
				  <li><a className="page-scroll" href="#rate">PRICING</a></li>
                  <li><a className="page-scroll" href="#amenities">Amenities</a></li>
                  <li><a className="page-scroll" href="#plans">Plan</a></li>
                  <li><a className="page-scroll" href="#location">Location</a></li>
                  <li><a className="page-scroll" href="#contact">Contact</a></li>
				  <li><a className="page-scroll" href="#" data-toggle="modal" data-target="#myModal"><b><i className="fa fa-download animated"></i> E-BROCHURE</b></a></li>
               </ul>
            </div>
         </div>
      </nav>
    
 
 
		 <header className="container-fluid md-3form">
	     <div className="row">
	     
			<div className="col-md-9 col-md-91">
				<div className="row">
		
		 
 
				 <div id="myCarousel" className="carousel slide " data-ride="carousel">
					 
						<div className="carousel-inner">
							<div className="item active">
								<img className="d-banner1" style={{width:"100%"}} src="img/s1.jpg" alt="Raunak Urban Centre"/>
							</div>
							<div className="item">
								<img className="d-banner1" style={{width:"100%"}} src="img/s2.jpg" alt="Raunak Urban Centre"/>
							</div>
							 
						</div>
						
						
					
					<a className="left carousel-control" href="#myCarousel" data-slide="prev">
					<i className="fa fa-arrow-left slicon-left"></i><span className="sr-only">Previous</span></a>
					<a className="right carousel-control" href="#myCarousel" data-slide="next"><i className="fa fa-arrow-right slicon-right"></i><span className="sr-only">Next</span></a>
					</div>
					</div>
					
	<div id="hero-content" className="text-align-center">
			<p className="top-patch">Booking Open</p>
			<div className="project-info">
				<p className="project-name">Raunak Urban Centre</p>
				<p className="project-extraname">Raunak Urban Centre</p>
				<p className="project-location">At Kalyan</p>
				<p className="project-developer">By Raunak Group</p>
			</div>
			<div className="project-addon-info">
				<p>→ Land Parcel: <b>35 Acres</b></p>
				<p>→ Possession: <b>Within 3 years (2024)</b></p>
				<p>→ <b>Super-Sized Homes In Kalyan</b></p>
			</div>
			<div className="project-booking-info">
				<div className="project-booking-info-points" >1 BHK Ready Possession Flats<p></p></div>
				<p className="project-booking-amount">EXCLUSIVE PRE-LAUNCH OFFER<br/>PAY JUST 5% & BOOK NOW</p>
 
			</div>
			<div className="project-configuration-details">
			 
				<p>1 & 2 BHK Spacious Starting Price</p>
			</div>
			<div className="project-price">
				<p>1 BHK @ 44 Lacs All Inc.</p>
				<p></p>
			</div>
			<div className="project-enquiry">
			   <a href="#" data-toggle="modal" className="btn btn-enq" data-target="#ganesh"><b><i class="fa fa-car faa-tada animated"></i> Book Site Visit</b></a>
			</div>
			<div className="project-rera">
				<p>RERA No: P52000016524 </p>
			</div>
	   </div>
					
					
				</div> 

				<div className="col-md-3 col-md-31 sl">
				<div className="row">
				  <div className="sliderform1">
				 				 
				 <h4>Register here for Best Offers</h4>
                     <p className="high_label ">
					 <span className="animated bounceIn infinite high_label_span">Limited Ready 1 BHK Flats Start @44 Lac All Inc.</span></p>
					 
					 
                     <form method="post" id="sliderform">
                        <div className="form-group">
                           <div className="input-group"> 
                              <span className="input-group-addon"><i className="fa fa-user"></i></span>
                              <input className="form-control" name="fname" id="fnamef" placeholder="Name" required />
                           </div>
                        </div>
                        <div className="form-group">
                           <div className="input-group"> 
                              <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                              <input type="email" className="form-control" name="email" id="emailf" placeholder="Email" required />
                           </div>
                        </div>
						<div className="form-group">
                           <div className="input-group"> 
                              <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                              <input type="text"  className="form-control" name="mobile" id="mobilef" placeholder="Mobile" />
                           </div>
                        </div>
                       
                      
                           <div className="form-group pop_align">
                              <input type="submit" className="btn btn-sl-form" value="Register → " />
                           </div>
                       
                        <div className="success_message4 pop_align"></div>
                     </form> 
		
 
					<div className="form-title">
						<ul>
							<li>Biggest Township in Kalyan</li>
							<li>PMAY benefits upto 2.67Lacs*</li>
							<li>Home to some of the tallest towers in the city of Kalyan, 35 storeys</li>
							<li>1 BHK Flats - Price Starts 44 Lakhs All Incl</li>
							 
							 			
						</ul>	
						
													
					</div>
					
				  </div>
				
				</div> 
				</div> 
	   </div>
    </header>
	
      <section className="contact_div form_mob">
         <div className="container ">
            <div className="row">
               <div className="col-md-8 col-md-offset-2 col-sm-9 col-sm-offset-1 text-center" >
                  <h4 className="form_mob_title">Pre-Register here for Best Offers</h4>
				   
                  
                   <form method="post" id="formmobile">
                <div className="form-group">
                    <input type="text" className="form-control form_mob_field" name="fname" id="fnamemid" placeholder="Name" required />
                </div>
                <div className="form-group">
                    <input type="email" className="form-control form_mob_field" name="email" id="emailmid" placeholder="Email" required />
                </div>
                <div className="form-group">

                    <input type="text" className="form-control form_mob_field" name="mobile" id="mobilemid" placeholder="Mobile" required />
                </div>
 
				<div className="col-md-3 col-md-offset-4">
					<div className="form-group">
						<input type="submit" className="btn btn-sl-form"  value="Register" />
					</div>
				</div>
				<div className="success_mobile pop_align"></div>
			</form>
                  
               </div>
            </div>
         </div>
      </section>
			
	
      <section className="bg-primary1" id="about">
         <div className="container">
            <div className="row">
                
                  <h1 className="section-heading sectionheading1 text-center">Raunak Urban Centre</h1>
                  <hr className="light1"/> 
					<div className="col-lg-8 text-center">
				 		<p><b>Pre Launch Raunak Urban Centre in Kalyan, Thane. </b></p>
						<p>Kalyan's first and biggest ever pre launch the first time in a private development in Kalyan. JIO Powered Township in Kalyan.</p>
						<p>Presenting <a href="https://raunakurbancentre.pre-launch.co/" class="url">Raunak Urban Centre</a> Kalyan. An iconic landmark that represent the best of Kalyan Thane. and Kalyan with its internationally-styled multi-area, mixed-use development. with iconic residential, commercial, retail and lifestyle avenies, designed and maintained by international experts.</p>
						<p>Raunak Codename Urban Centre is Kalyan biggest development spread across 35 acres. Raunak Codename Urban Centre Kalyan is a striking example of meticulous planning, stunning features and architectural innovation.</p>
						
						<p>Raunak Urban Centre is the Pre Launch Project by Raunak Developers in Kalyan. Raunak Codename Urban Centre defined by its exclusive location, architecture, amenities & connectivity. Raunak Urban Centre Kalyan Spread in the 35 acers of huge land parcel. Urban Centre City Kalyan offers you excellent private views and is designed to offer optimum cross ventilation thus providing access to natural breeze and Sunlight. Throw open your windows to a draft of the coolest, most sublime breeze and stunning views of green.</p>
												 
						<div className="form-group"><input type="submit" data-toggle="modal" data-target="#ganesh" className="btn btn-sl-form" value="Pick Up & Drop Facility" /></div>
						 
					</div>
				  
					  <div className="col-lg-4 text-center">
						<img src="img/tulsi-about.jpg" className="aboutimg img-responsive text-center" alt="Raunak Urban Centre Kalyan"  title="Raunak Urban Centre Kalyan"/>
					  </div>
            </div>
         </div>
      </section>
	  
		 <section id="rate" className="rate">
			<div className="container rate3-sub">
				<div className="row">
					<div className="col-lg-12 text-center">
						<h2 className="section-heading-rate">AREA & PRICING</h2>
						<hr className="primary" />
					</div>
				</div>
				<div className="col-lg-12 col-sm-12">
					<div className="row">
						 <div className="columns wow fadeInDown">
						  <ul className="price">
							<li className="header">1 BHK With Master Bedroom Ready</li>
							<li className="grey"><b>Carpet Area	</b> » <br/>On Request Sq.ft.(Area)<br/></li>
							 
							<center><hr/></center>
							<li className="grey"><a href="#" data-toggle="modal" className="btn btn-primary" data-target="#pricing">₹ 44 Lakhs All Incl</a></li>
						  </ul>
						</div> 
						<div className="columns wow fadeInDown">
						  <ul className="price">
							<li className="header">1 BHK Grand</li>
							<li className="grey"><b>Carpet Area</b> » <br/>On Request Sq.ft.(Area)</li>
							<center><hr/></center>
							<li className="grey"><a href="#" data-toggle="modal" className="btn btn-primary" data-target="#pricing">₹ 45 Lakhs All Incl</a></li>
						  </ul>
						</div> 
						
						
					</div> 
					<h3 className="pop_align1"></h3>
					 
				</div> 
			</div>
			
			<div className="container rate-sub1">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading-rate">PAYMENT SCHEDULE</h2>
				  <hr className="light"/>
               </div>
            </div>
            
			
			<div className="col-lg-4 col-sm-4  col-md-offset-4">
               
                  <a href="#" data-toggle="modal"  data-target="#myModals"><img src="img/costing.jpg" className="img-responsive costing " alt="Kalpataru Parkcity Thane"  title="Kalpataru Parkcity Thane"/></a>
                
               <h3 className="pop_align1"><a href="#" data-toggle="modal" className="btn btn-primary" data-target="#pricing">₹ Complete Costing Details</a></h3>
			   
            </div>
		 
         </div>
		 <h3 className="pop_align1">For booking process please call us now: +91 9130568016</h3>
		</section>
	 		
    
	 <section id="amenities">
         <div className="container amenities-sub1">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading sectionheading1">Amenities</h2>
                  <hr className="primary"/>
               </div>
            </div>
			
			
			<div className="row1">
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/clubhouse.png" alt="" height="75"/>
								  <p> Club House </p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/running.png" alt="" height="75"/>
								  <p> Jogging Track</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/dumble.png" alt="" height="75"/>
								  <p> Gymnasium </p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/squash.png" alt="" height="75"/>
								  <p> Squash Court </p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/pool.png" alt="" height="75"/>
								  <p> Swimming Pool</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/hall.png" alt="" height="75"/>
								  <p> Multipurpose Hall</p>
							</div>
						</div>

						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/cafe.png" alt="" height="75"/>
								  <p> Cafe</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/gamepad.png" alt="" height="75"/>
								  <p> Indoor Game Room</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/medtation.png" alt="" height="75"/>
								  <p>Yoga Lawn</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/reflexology.png" alt="" height="75"/>
								  <p> Reflexology Pool</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/kids-pay.png" alt="" height="75"/>
								  <p> Kid's Play Area</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/senior.png" alt="" height="75"/>
								  <p> Senior Citizen's Area</p>
							</div>
						</div>
						
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/ami.png" alt="" height="75"/>
								  <p> Amphith eatre</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/lib.png" alt="" height="75"/>
								  <p> E-Library</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/park.png" alt="" height="75"/>
								  <p> Central Greens</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/cctv.png" alt="" height="75"/>
								  <p> Security</p>
							</div>
						</div>
						 
					</div>  
 
	   
         </div>
      </section>
    
	  
 


      <section id="plans"  className="bg-primary">
         <div className="container">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading sectionheading1">Raunak Urban Centre Floor Plans </h2>
                  <hr className="primary"/>
               </div>
            </div>
         </div>
         <div className="container">
            <div className="row no-gutter  wow fadeInUp">
               <div className="col-lg-10 col-sm-10 col-xs-12 col-md-offset-2">
				   <div className="col-md-4 col-xs-12">
						<h3 style={{textAlign:"center"}}>Layout Map</h3>
					  <a href="#" data-toggle="modal" data-value="2 BHK" data-target="#floorplan" >
						 <img src="img/floorplan/layout.jpg"  className="img-responsive" alt="Raunak Urban Centre" title="Raunak Urban Centre"/>
						  
					  </a>
				   </div>
				   <div className="col-md-2 col-xs-12">
				   </div>
				   <div className="col-md-4 col-xs-12">
					<h3 style={{textAlign:"center"}}>Floor Plans</h3>
					  <a href="#" data-toggle="modal" data-value="2 BHK" data-target="#floorplan" >
						 <img src="img/floorplan/floorplan-hinde.jpg" style={{width:"100%"}} className="img-responsive" alt="Raunak Urban Centre" title="Raunak Urban Centre"/>
						  
					  </a>
				   </div>
               </div>
               			   
			   
            </div>
         </div>
      </section>
    
      
      <section id="location">
         <div className="container">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading sectionheading1">Location</h2>
                  <hr className="primary"/>
               </div>
            </div>
         </div>
         <div className="container">
            <div className="row">
				<div className="col-lg-7 col-sm-7 pop_align">
					<div className="col-lg-12 col-sm-12">	
						 <a href="#myModals">
						 <img src="img/location.jpg" alt="Location Raunak Urban Centre" title="Raunak Urban Centre" data-toggle="modal" data-target="#myModals" className="img-responsive pop_align" /><br/>
						 </a>
					</div>
			    </div> 
				<div className="col-sm-5">
					<div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
						<div className="panel panel-default active">
							<div className="panel-heading" role="tab" id="headingOne">
								<h4 className="panel-title">
									<a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
										<i className="more-less fa fa-plus" aria-hidden="true"></i>
										Kalyan at its inflection point
									</a>
								</h4>
							</div>
							<div id="collapseOne" className="panel-collapse" role="tabpanel" aria-labelledby="headingOne">
								<div className="panel-body">
									  <ul>
										<li>Upcoming 24 Km Taloja-Kalyan metro line</li>
										<li>6 lane bridge on Ulhas river</li>
										<li>Proposed freeway from Airoli to Kalyan</li>
										<li>KDMC to develop kalyan creek on lines of sabarmati river front</li>
										 
									  </ul>
								</div>
							</div>
						</div>

						</div>
				</div>

			   
            </div>
			<div className="col-lg-12 col-sm-12">	
					<h3 class="pop_align"><b>ADVANTAGES OF LOCATION</b></h3>
					<p><b>With its center location, ease of connectivity and superlative infrastructure, Kalyan offers Thane finest avenues in commerce, lifestyle and culture.</b></p>
					 
					<p><b>A new mega-hub of infrastructure and growth.</b>In recent times there has been a strong focus on Kalyan as the new centre of development with tremendous Government impetus, initiatives and investment. Besides high infrastructure growth, the superior air quality and  microclimate, dedicated open spaces, abundance of nature and excellent connectivity make Kalyan a true mega-destination of the future. </p>
					</div>
         </div>
      </section>

      <aside className="bg-dark">
         <div className="container text-center">
            <div className="call-to-action">
               <h3>For more Information call : +91 91305 68016</h3>
               <a href="#myModal" data-toggle="modal" data-target="#myModal" className="btn btn-default btn-xl sr-button">Download Brochure!</a>
            </div>
         </div>
      </aside>

      
	  
	 

 <section id="contact"  >
         <div className="container">
            <div className="row">
				<div className="col-lg-6 text-center">
                  <h3 className="section-heading">About Developer</h3>
                  <hr className="light"/>
					
					<p>Raj group specializes in all aspects of real-estate; it offers extended portfolio management of large, mid-sized, and small residential properties.Our core strengths are focused on scrupulous property construction, with the support of a diligent and committed team, who delivers solutions that matches the highest level of customer specification and satisfaction. We @ Raj Group understand the world we live in is changing at a swift pace. </p>
               </div>
			   
               <div className="col-lg-6 text-center">
                  <h3 className="section-heading">Lets Get In Touch! </h3>
                  <hr className="primary"/>
                  <form method="post" id="contactinq">
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-user"></i></span>
                           <input className="form-control" name="fname" id="fname" placeholder="Name" required/>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                           <input type="email" className="form-control" name="email" id="email" placeholder="Email" required/>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                           <input type="text" className="form-control" name="mobile" id="mobile" placeholder="Mobile" required/>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-comment"></i></span>
                           <textarea className="form-control" name="mesg" id="mesg" placeholder="Massage" rows="4"></textarea>
                        </div>
                     </div>
                     <div className="pop_align">
                        <div className="form-group">
                           <input type="submit" className="btn btn-sl-form" value="Submit"/>
                        </div>
                     </div>
                     <div className="success_message pop_align"></div>
                     <br/>
                  </form>
               </div>
               
			   <div className="col-lg-12 text-center">
                  <i className="fa fa-phone fa-3x sr-contact"></i>
                  <h3>+91 9130568016</h3>
               </div>
			   
			   
            </div>
         </div>
      </section>

      <div className="bg-dark">
         <div className="container text-center">
            <div>
               
			   <div className="reranumber"><b>Project - MahaRERA No.:</b> P52000016524</div>
               <div className="disclaimer"><b>Disclaimer:</b>  This Website Is Managed By Raunak Urban Centre Kalyan, Navi Mumbai Authorised Media Partner.</div>
          <br/><br/>
            </div>
         </div>
      </div>

      <div className="whatsapp-area">
         <div className="chat-button-area zoomOut">
            <button type="button" id="whatsapp-btn"  className="whatsapp-btn"> 
            <a href="https://wa.me/919082164514?text=Hi.. I am interested in Raunak Urban Centre Project. Please help me with the details. Thanks!" target="_blank">
            <span className="icon-whatsapp"></span> <i className="fa fa-whatsapp"></i>
            </a>
            </button> 
            <div className="circle-animation">
            </div>
         </div>
      </div>
    
    </div>
  );
}

export default App;
